import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Maintenance from './Pages/UnderMaintenance';
import Header from './Components/Header/Header';
import CookieBanner from './Components/Banner/Banner';
import Challenges from './Pages/Challenges';
import Desafios from './Pages/Desafios';
import Quemsomos from './Pages/Quemsomos';
import Aboutus from './Pages/Aboutus';
import Contacts from './Pages/Contacts';
import Contactos from './Pages/Contactos';
import Reservas from './Pages/Reservas';
import Bookings from './Pages/Bookings';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [language, setLanguage] = useState(); // Initialize language state with default value

  //eslint-disable-next-line
  const [menuItem, setMenuItem] = useState();

  const handleMenuClick = (page, lang) => {
    if(lang !== language) { //if the language changed, a reload is needed
      setMenuItem(page);
      setLanguage(lang);
      localStorage.setItem('selectedLanguage', lang);
      window.location.reload();
    }
    else {
      setMenuItem(page);
      setLanguage(lang);
      localStorage.setItem('selectedLanguage', lang);
    }
  }

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      setTimeout(() => {
        setIsUnderMaintenance(false);
        setIsLoading(false);

        const selectedLanguage = localStorage.getItem('selectedLanguage') || 'pt';
        setLanguage(selectedLanguage);
        setMenuItem(2);
      }, 300); // Simulating 0.5s for loading
    };

    fetchMaintenanceStatus();
  }, []);

  const getRedirectPath = () => {
    const lang = localStorage.getItem('selectedLanguage') || 'pt';
    return lang === 'pt' ? '/desafios' : '/challenges';
  };

  return (
    <div className="App">
      {isLoading ? (
        <div className='loadingContainer'><h1 className='loadingText'>Loading...</h1></div>
      ) : (
        isUnderMaintenance ? (
          <Maintenance/>
        ) : (
          <Router>
            <div>      
              <Header onHeaderClick={handleMenuClick}/>
              
              <Routes>
                <Route path="/" element={<Navigate to={getRedirectPath()} />} />
                {language === 'pt' ? (<Route path='/desafios/' element={<Desafios/>} />) : (<Route path='/challenges' element={<Challenges/>} />)}
                {language === 'pt' ? (<Route path='/reservas/' element={<Reservas/>} />) : (<Route path='/bookings' element={<Bookings/>} />)}
                {language === 'pt' ? (<Route path='/quemsomos/' element={<Quemsomos/>} />) : (<Route path='/aboutus' element={<Aboutus/>} />)}
                {language === 'pt' ? (<Route path='/contactos/' element={<Contactos/>} />) : (<Route path='/contacts' element={<Contacts/>} />)}
                <Route path="*" element={<Navigate to={getRedirectPath()} />} /> {/*Any error or unknown path*/}
              </Routes>
              <CookieBanner/>
            </div>
          </Router>
        )
      )}
    </div>
  );
};

export default App;